import Splitting from "splitting";

import { $, $$ } from "@utils/dom";


const splitByWordsForMaskAnimation = (el, options, ctx) => {

  const { words } = ctx;
  words.forEach(word => word.innerHTML = `<span class="wordText">${word.innerHTML}</span>`);

  return [];
};

const writeLineTotalInTargetCssVar = (el, options, ctx) => {
  if(el.dataset.scrollTarget){
    $(el.dataset.scrollTarget).style.setProperty('--line-total', ctx.lines.length);
  }

  return [];
};

// split by words and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'wordsMask',
  key: 'wordsMask',
  depends: ['words'],
  split: splitByWordsForMaskAnimation,
});

// split by lines and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'animatePerLines',
  key: 'animatePerLines',
  depends: ['wordsMask', 'lines'],
  split: writeLineTotalInTargetCssVar
});


export default () => {
  [ ...$$('[data-splitting]') ].forEach(el => {
    const splittingMethod = el.dataset.splitting || "wordsMask";

    Splitting({
      target: el,
      by: splittingMethod
    });
  });
}
